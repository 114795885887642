//Modules and Providers
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ErrorHandler } from "@angular/core";
import { Errors } from './errorhandle/custom-errorhandle';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms'
import { NgxPaginationModule } from 'ngx-pagination';
import { TemplateModule } from './view/template/template.module';
import { TokenInterceptor } from './services/authentication/token-interceptor';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChartsModule } from 'ng2-charts';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import {WebcamModule} from 'ngx-webcam';
import { CodeInputModule } from 'angular-code-input';
import { NgCircleProgressModule } from 'ng-circle-progress';

//Components
import { AppComponent } from './app.component';
import { LoginComponent } from './view/login/login.component';

//Services
import { AuthenticationService } from './services/authentication/authentication.service'
import { MessageService }       from './services/message.service';
import { LoaderService } from './services/loader/loader.service';
import { HomeComponent } from './view/home/home.component';
import { LoaderComponent } from './view/loader/loader.component';
import { UserClockComponent } from './view/user-clock/user-clock.component';
import { CameraComponent } from './view/customs/camera/camera.component';
import { FacecapturedComponent } from './view/customs/facecaptured/facecaptured.component';




@NgModule({
  declarations: [
    AppComponent,       
    LoginComponent, HomeComponent  ,LoaderComponent, UserClockComponent, CameraComponent, FacecapturedComponent
  ],
  imports: [
    BrowserModule,  
    CodeInputModule,
    ShowHidePasswordModule,
    AppRoutingModule,
    TemplateModule,
    HttpClientModule,
    TranslateModule,
    FormsModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule ,
    NgxPaginationModule  ,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,   
    NgSelectModule,
    NgxAudioPlayerModule  ,
    ChartsModule,
    WebcamModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 4,
      innerStrokeWidth: 2,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      titleColor:"#78C000",
      startFromZero:false,
      animationDuration: 300,
    })
  ],
  providers: [
    AuthenticationService,
    LoaderService,    
    MessageService,
    {provide: ErrorHandler, useClass: Errors},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
    
  ],
  bootstrap: [AppComponent]  
})
export class AppModule { }
