import { Component, OnInit, Inject, HostListener, ViewChild, ElementRef } from '@angular/core';
import { DOCUMENT, formatDate } from '@angular/common';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Config } from 'src/app/services/authentication/Config';
import { Router } from '@angular/router';
import { User } from 'src/app/services/users/User';
import { API, MessageType } from 'src/app/services/api-global';
import { format } from 'url';
import { KioskSettings } from 'src/app/services/KioskSettings/KioskSettings';
import { KiosksettingsService } from 'src/app/services/KioskSettings/kiosksettings.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { HttpErrorResponse } from '@angular/common/http';
import { async } from '@angular/core/testing';
import { LoginResult } from 'src/app/services/authentication/LoginResult';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { KioskUsersReadService } from 'src/app/services/kioskuser/kiosk-users-read.service';
import { UserKiosk } from 'src/app/services/kioskuser/UserKiosk';
import { CompanySettings } from 'src/app/services/settings/CompanySettings';
import { KioskCloking } from 'src/app/services/KioskSettings/KioskCloking';
import { element } from 'protractor';
import { FacecapturedComponent } from '../customs/facecaptured/facecaptured.component';
import { CameraComponent } from '../customs/camera/camera.component';
import { WebcamImage } from 'ngx-webcam';

const api = new API();



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [   

    trigger('EnterLeave', [
      state('fly', style({ transform: 'translateX(0)' })),
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('0.3s ease-in')
      ])
    ])
  ]
})
export class HomeComponent implements OnInit {
  [x: string]: any; 

  users:UserKiosk[] = [];
  usersFilter:UserKiosk[] = [];
  usersPaginated:UserKiosk[] = [];
  page:number = 1;
  total:number = 0;
  maxPerPage:number = 10;
  date:string = "Loading Date...";
  time:string = "00:00 am";
  weekday:string[] = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  kioskSettings:KioskSettings = new KioskSettings({showUsersList:false});
  pin:string = "";
  userId:string = "";
  codeLength:number = 4;
  logo:string = "";
  selectedUser:UserKiosk = new UserKiosk();
  modeUI:number = 0; //0- Select User, 1- Pin Mode
  timerBack:number = 20000;
  timeLeftHome:number = this.timerBack;
  intervalBackButton;
  intervalBackButtonExe;
  companySettings:CompanySettings=new CompanySettings();
  cloking:KioskCloking;
  currentLatitude:number=-1;
  currentLongitude:number=-1; 
  filterType:string = "firstName";
  showWebcam:boolean = false;

  @ViewChild("facecaptured") facecaptured: FacecapturedComponent;
  @ViewChild("cameraWithButton") cameraWithButton: CameraComponent;
  @ViewChild("cameraAuto") cameraAuto: CameraComponent;

  constructor(private kioskUserService:KioskUsersReadService,private kioskService:KiosksettingsService, private load:LoaderService,private kioskSettingsServise:KiosksettingsService, private authentication:AuthenticationService,private router: Router,private formBuilder: FormBuilder,@Inject(DOCUMENT) private document: any) {  
    
   }

   PrepareCamera(){    
    this.facecaptured.PrepareCamera(this.kioskSettings.cameraMode);
  }

  updateGeolocation(){       
    this.currentLatitude = -1;
    this.currentLongitude = -1;
    window.navigator.geolocation.getCurrentPosition(
      (position) => {      
        this.currentLatitude = position.coords.latitude;
        this.currentLongitude = position.coords.longitude;       
      },
      (failure) => { 
        if(this.kioskSettings.requireDeviceGeoLocation || this.kioskSettings.enforceDeviceGeoFence){
          api.Message(MessageType.Error,"Unable find Geolocation","Unable to find Geolocation, please allow use of geolocation.");
          api.Message(MessageType.Error,"Error Details",failure.message);
        }       
               
      }
    );
  }

  async ngOnInit() {    
    await this.refreshLogin()
    this.updateGeolocation();
    this.calculePagination();
    this.loadKioskSettings();
    this.getCompanySettings();
    this.load.show();
    this.updateClock();          
    this.getUserTable();
    api.openFullscreen();  
    setInterval(() => {    
      this.updateClock();
   }, 1000);
   this.logo = api.getConfig()["LOGO"];   
   this.load.hidde();
  }

  async refreshLogin(){
    let login: KioskSettings = this.authentication.loadKioskResult();
    await this.authentication.loginEnpoint(login);
  }

  loadKioskSettings(){
    this.load.show();    
    let login: KioskSettings = this.authentication.loadKioskResult();   
    if(login.showUsersList)
      this.homeMessage = "To get started, select your name from the list.";
    else
      this.homeMessage = "To get started, type your id.";
    this.kioskService.getKioskSettings(login.id.toString()).subscribe(data=>{      
      this.kioskSettings = data.body;      
      this.authentication.saveKioskSettings(this.kioskSettings);   
      this.load.hidde(); 
    },error=>{this.PosibleErrorsKioskSettings(error);})
  }

  changeFilter(filter:string){
    if(this.filterType=="firstName"){
      this.usersFilter = this.users.filter(function (user:UserKiosk){
        return user.firstName.includes(filter);
      });      
    }
    if(this.filterType=="lastName"){
      this.usersFilter = this.users.filter(function (user:UserKiosk){
        return user.lastName.includes(filter);
      });      
    }
    if(this.filterType=="userId"){
      this.usersFilter = this.users.filter(function (user:UserKiosk){
        return user.userId.includes(filter);
      });              
    }
    this.total = this.usersFilter.length;
    this.page = 1;
    
  }

  changeFilterType(filterType:string){
    this.filterType = filterType; 
  }
  

  calculePagination(){
    let screen_size_h = window.screen.height;   
    if(screen_size_h<650){
      this.maxPerPage = 8;
      return;
    }
    if(screen_size_h<670){
      this.maxPerPage = 9;
      return;
    }
    if(screen_size_h<700){
      this.maxPerPage = 10;
      return;
    }
    if(screen_size_h<761){
      this.maxPerPage = 10;
      return;
    }
    if(screen_size_h<805){
      this.maxPerPage = 10;
      return;
    }
    if(screen_size_h<855){
      this.maxPerPage = 11;
      return;
    }
    if(screen_size_h<900){
      this.maxPerPage = 12;
      return;
    }
    if(screen_size_h<950){
      this.maxPerPage = 13;
      return;
    }
    if(screen_size_h<1050){
      this.maxPerPage = 14;
      return;
    }
    if(screen_size_h<1100){
      this.maxPerPage = 15;
      return;
    }
    if(screen_size_h>=1100){
      this.maxPerPage = 16;
      return;
    }
  }

  fullScreen(){    
    api.openFullscreen();
  }

  get IsFullScreen(){
    return api.isFullScreen();
  }

  getCompanySettings(){
    this.kioskSettingsServise.getCompanySettings().subscribe(data=>{
      this.companySettings = data.body;
    },error=>{
      this.PosibleErrorsKioskSettings(error);
    })
  }

  loadUser(user:UserKiosk){
    this.updateGeolocation();       
    this.selectedUser = user;
    let countOptions = 0;
    let lastOption = 1;
    if(this.kioskSettings.usePinVerification){
      countOptions++;
      lastOption = 2;
    }
    if(this.kioskSettings.useCardVerification){
      countOptions++;
      lastOption = 3;
    }
    if(this.kioskSettings.useBarCodeVerification){
      countOptions++;
      lastOption = 4;
    }          
    if(this.kioskSettings.useFaceVerification){
      countOptions++;
      lastOption = 5;
    }
    if(this.kioskSettings.useFingerPrintVerification){
      countOptions++;
      lastOption = 6;
    }    
    if(this.kioskSettings.useQRCodeVerification){
      countOptions++;
      lastOption = 7;
    }
    if(countOptions>0){
      if(countOptions==1)
        this.modeUI = lastOption;      
      else
        this.modeUI = 1;  
      this.prepareToBack(); 
    }
    else{
      api.Message(MessageType.Error,"No Options","There are no identification options");
    }
     
  }
  backHome(){
    clearInterval(this.intervalBackButton);
    clearTimeout(this.intervalBackButtonExe);
    this.modeUI = 0;
    this.pin = "";
    this.selectedUser = null;
    this.showWebcam = false;
  }

  pinMode(){
    this.modeUI = 2;    
    clearInterval(this.intervalBackButton);
    clearTimeout(this.intervalBackButtonExe);
    this.prepareToBack();
  }

  capturePhoto(){
    this.facecaptured.PrepareCamera(this.kioskSettings.cameraMode);
  }

  prepareToBack(){
    this.timeLeftHome = this.timerBack;
    this.intervalBackButton = setInterval(()=>{
      if(this.timeLeftHome>0)
        this.timeLeftHome-=1000;
    },1000);

    this.intervalBackButtonExe = setTimeout(()=>{      
     this.backHome();      
    },this.timeLeftHome);
  }

  prepareToBackFaceMode(){
    this.timeLeftHome = this.timerBack;
    this.intervalBackButton = setInterval(()=>{
      if(this.timeLeftHome>0)
        this.timeLeftHome-=1000;
    },1000);

    this.intervalBackButtonExe = setTimeout(()=>{  
      this.facecaptured.stopVideo();    
      this.backHome();      
    },this.timeLeftHome);
  }

  onCodeCompleted(code: string)   {
    
  }
  add(d:string){
    if(this.pin.length<this.codeLength)
       this.pin = this.pin + d;
  }
  del(){
    if(this.pin.length>0){
      this.pin = this.pin.substr(0,this.pin.length-1)
    }
  }
 
  addPicture(picture:string){
    this.cloking.UserPicture = picture.substr(picture.indexOf(",")+1,picture.length);
    this.submmitClock();
  }
  takePicture(image:WebcamImage){
    let picture = image.imageAsBase64;
    this.cloking.UserPicture = picture.substr(picture.indexOf(",")+1,picture.length);
    this.submmitClock();
  }
  submmitClock(){
    if(this.kioskSettings.enforceDeviceGeoFence){
      if(this.currentLatitude!=-1&&this.currentLongitude!=-1){
        this.cloking.Longitude = this.currentLongitude;
        this.cloking.Latitude = this.currentLatitude;
      }
      else{
        api.Message(MessageType.Error,"Geolocation Miss","Setting Enforce Device Geo Fence is on, we need to verify your Geolocation, please allow Location inyour Browser.");
        this.updateGeolocation();
        this.backHome(); 
      }
    }
    this.load.show();
      this.kioskService.sendKioskCloking(this.cloking).subscribe(data=>{
        this.load.hidde();      
        api.Message(MessageType.Success,"Verification Complete","User ("+this.selectedUser.firstName+" "+this.selectedUser.lastName+") has been verified.");      
        this.cloking = new KioskCloking();
        this.backHome();
      },error=>{this.PosibleErrorsKioskCloking(error);this.backHome();});
  }
  takeSmartPhotoSetup(){
    this.modeUI = 5;
      clearInterval(this.intervalBackButton);
      clearTimeout(this.intervalBackButtonExe);    
      setTimeout(()=>{        
        this.facecaptured.PrepareCamera(this.kioskSettings.cameraMode);        
        this.prepareToBackFaceMode();
      },500);
  }

  takePhotoWithButtonSetup(){
    this.modeUI = 6;
      clearInterval(this.intervalBackButton);
      clearTimeout(this.intervalBackButtonExe);        
      this.showWebcam = true;  
      setTimeout(()=>{
        this.prepareToBack();
      },200);
     
  }
  takePhotoAutoSetup(){    
    this.modeUI = 7;
    clearInterval(this.intervalBackButton);
    clearTimeout(this.intervalBackButtonExe);   
    this.showWebcam = true;        
    this.timeLeftHome = 3000;
     
  }

  cameraEndsLoad(camera:string){
    this.timeLeftHome = 3000;
      this.intervalBackButton = setInterval(()=>{
      if(this.timeLeftHome>0)
        this.timeLeftHome-=1000;
      },1000);

      this.intervalBackButtonExe = setTimeout(()=>{      
        this.cameraAuto.triggerSnapshot();     
      },this.timeLeftHome);
  }

  
 
  takePhotoWithButton(){
    this.cameraWithButton.triggerSnapshot();
  }
  submmitPin(){   
    this.cloking = new KioskCloking({Altitude:0,Longitude:this.currentLongitude,Latitude:this.currentLatitude,CurrentLocalTime:this.kioskService.getKioskTimeFormatted(),PinCode:this.pin,UserId:+this.selectedUser.userId,VerificationMethod:1,VerificationMode:1})
    if(this.kioskSettings.requireUserPicture){
      switch(this.kioskSettings.cameraMode){
        case 1:
          this.takePhotoWithButtonSetup();
          break
        case 2:     
          this.takePhotoAutoSetup();
          break
        case 3:
         this.takeSmartPhotoSetup();
         break;
        case 4:
         this.takeSmartPhotoSetup();
         break;
        case 5:
         this.takeSmartPhotoSetup();
         break;
      }

         
    }
    else{
      this.submmitClock();
    }
        
  }

  addId(d:string){
    this.userId = this.userId + d;       
  }
  delId(){
    if(this.userId.length>0)
      this.userId = this.userId.substr(0,this.userId.length-1)
  }
  submmitId(){   
    this.updateGeolocation();     
    this.selectedUser = this.users.find(u=>u.userId == this.userId);
    
    if(this.selectedUser == null){
      api.Message(MessageType.Error,"User not Found","User id("+this.userId+") not found, please refresh and try again.")
    }
    else{
      this.loadUser(this.selectedUser);
    }   
  }

  getUserTablePaginated(){
    
  }

  onChangePin(last:string){
    this.pin = last;
  }

  updateClock(){
    let today = new Date();
    this.date = this.weekday[today.getDay()]+", "+ formatDate(today,"MMMM dd","en-US");
    this.time = formatDate(today,"hh:mm:ss a","en-US");

  }

  getUserTable(){
    this.load.show();
    this.users = [];    
    this.loadUsers(1); 
  }

  loadUsers(p:number){
    this.kioskUserService.getUsers(p,this.maxPerPage).subscribe(
      (data=>{     
      this.total = +data.headers.get("X-Total-Count");
      data.body.forEach(element => {
        this.users.push(element);
      });   
      if(this.users.length<this.total){
        this.loadUsers(p+1);
      }  
      else{
        this.load.hidde();
        this.usersFilter = this.users;     
      }
        
    }),
    error=>{this.PosibleErrorsKioskUsers(error);});  
  }
  
  PosibleErrorsKioskUsers(error:HttpErrorResponse)
  {
    this.load.hidde();
    switch(error.status)
       {
        case 0:
          api.Message(MessageType.Error, "Connexion Error", "Please check your Internet.");
          break;         
         case 401:
          this.authentication.logOut();  
          api.Message(MessageType.Error,"Session Time Out","Please login again.");              
         break;
         case 403:
         api.Message(MessageType.Error,"Api Unaccesible","Not Access to Api");         
         break;          
         default:
         api.Message(MessageType.Error,"Unknow Error",error.message);        
         break; 
       }
  }

  PosibleErrorsKioskCloking(error:HttpErrorResponse)
  {
    this.load.hidde();
    switch(error.status)
       {
        case 0:
          api.Message(MessageType.Error, "Connexion Error", "Please check your Internet.");
          break;         
         case 401:
          this.authentication.logOut();  
          api.Message(MessageType.Error,"Session Time Out","Please login again.");              
         break;
         case 403:
          if(error.error=="invalid_pin"){
            api.Message(MessageType.Error,"Wrong Pin","Wrong pin,please try again."); 
            break; 
          }   
          api.Message(MessageType.Error,"API Inaccesible","Server timeout.");
          break;
         break;          
         default:
         api.Message(MessageType.Error,"Unknow Error",error.message);        
         break; 
       }
  }

  
  PosibleErrorsKioskSettings(error:HttpErrorResponse)
  {
    this.load.hidde();
    switch(error.status)
       {
        case 0:
          api.Message(MessageType.Error, "Connexion Error", "Please check your Internet.");
          break;         
         case 401:
          this.authentication.logOut();  
          api.Message(MessageType.Error,"Session Time Out","Please login again.");              
         break;
         case 403:
         api.Message(MessageType.Error,"Api Unaccesible","Not Access to Api");         
         break;          
         default:
         api.Message(MessageType.Error,"Unknow Error",error.message);        
         break; 
       }
  }


  

}
