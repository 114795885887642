import PNotify from 'pnotify/dist/es/PNotify';
import PNotifyButtons from 'pnotify/dist/es/PNotifyButtons';
import { stringify } from 'querystring';
import { LoaderService } from './loader/loader.service';
import { Title } from '@angular/platform-browser';
import config from '../../assets/config/settings.json';
import * as fingerprint2 from 'fingerprintjs2';
import * as UAParser from 'ua-parser-js'

export enum MessageType
{
    Error,
    Info,
    Success,
    Warning
}

export class API
{
    public ApiUrl = config["API"];
    //public ApiUrl = "http://localhost:8087/api/";
   // public ApiUrl = "http://localhost:8088/api/";
   uid:string = "";
   elemDoc;

    public getConfig(){ return config;}
  
    public EnPoints= 
    {
        Login : this.ApiUrl + "api/online-kiosk/registration",
        LoginAutomatic: this.ApiUrl + "api/online-kiosk/login",        
        KioskSettings: this.ApiUrl + "api/public/OnlineKioskSettings",
        KioskUsers: this.ApiUrl + "api/online-kiosk/OnlineKioskUsersRead",
        KioskCloking: this.ApiUrl + "api/online-kiosk/OnlineKioskClocking",
        KioskSettingsRead: this.ApiUrl + "api/online-kiosk/OnlineKioskRead",
        GlobalKioskSettingsRead: this.ApiUrl + "api/online-kiosk/OnlineKioskSettingsRead",
        KioskCompanySettings: this.ApiUrl + "api/online-kiosk/OnlineKioskCompanySettingsRead",
        
        
        Users : this.ApiUrl + "api/public/User",
        Departments: this.ApiUrl + "api/public/Department",
        Locations: this.ApiUrl + "api/public/Location",
        Biometrics: this.ApiUrl + "api/public/Biometrics",
        Devices: this.ApiUrl + "api/public/Device",
        DeviceAction: this.ApiUrl + "api/public/DeviceAction",
        TimeLogs: this.ApiUrl + "api/public/UserTimeLog",
        ConfigUser: this.ApiUrl + "api/public/ConfigUser",
        WhosIn: this.ApiUrl +"api/public/WhosIn",
        TimeZone: this.ApiUrl +"api/public/TimeZoneInfo",
        Countrys: this.ApiUrl +"api/public/Country",
        CompanySettings: this.ApiUrl +"api/public/CompanySetting",
        CompanyCustomField: this.ApiUrl +"api/public/CompanyCustomSetting"        
    }    
    constructor()
    {
        PNotifyButtons; // Initiate the module. Important!    
    }

    getTitle(){
      return config["TITLE"];
    }

    getImage(){
      return config["TESTIMAGE"];
    }

    openFullscreen() {
        this.elemDoc = document.documentElement; 
      
        /*if (this.elemDoc.requestFullscreen) {
          this.elemDoc.requestFullscreen();
        } else */if (this.elemDoc.mozRequestFullScreen) {
          /* Firefox */
          this.elemDoc.mozRequestFullScreen();
        } else if (this.elemDoc.webkitRequestFullscreen) {
          /* Chrome, Safari and Opera */
          this.elemDoc.webkitRequestFullscreen();
        } else if (this.elemDoc.msRequestFullscreen) {
          /* IE/Edge */
          this.elemDoc.msRequestFullscreen();
        }
      } 
      
      isFullScreen(){
        return (document['fullscreenElement']);
      }

    Message(type:MessageType,titleMsg:string,msg:string)
    {
        let typeError= "notice";

        switch(type)
        {
            case MessageType.Error:
            typeError = "error";
            break;
            case MessageType.Info:
            typeError = "info";
            break;
            case MessageType.Success:
            typeError = "success";
            break;
            case MessageType.Warning:
            typeError = "warning";
            break;
        }

        PNotify.alert({
            title: titleMsg,
            text: msg,
            type: typeError
          });   
    }

    async getHash () {
      const options = {
        excludes: {
          plugins: true,
          localStorage: true,
          adBlock: true,          
          screenResolution: true,
          availableScreenResolution: true,
          enumerateDevices: true,
          pixelRatio: true,
          doNotTrack: true
        },
        preprocessor: (key, value) => {
          if (key === 'userAgent') {
            const parser = new UAParser(value)       
            return `${parser.getOS().name} :: ${parser.getBrowser().name} :: ${parser.getEngine().name}`
          }
          return value
        }
      }
    
      try {
        const components = await fingerprint2.getPromise(options);
        const values = components.map(component => component.value);  
        let re = String(fingerprint2.x64hash128(values.join(''), 31));     
        this.uid = re; 
        localStorage.setItem("GUID",re);        
        return this.uid;
      } catch (e) {
        console.log(e);
      }
    }

     generateUID(){      
        return this.getHash();    
      }
}
