export class KioskCloking
{
    public constructor(init?:Partial<KioskCloking>) {
        Object.assign(this, init);
    
    }
    UserId: number;
    VerificationMethod:number;
    VerificationMode:number;
    PinCode:string;
    Latitude:number;
    Longitude:number;
    Altitude:number;
    Note:string;
    CurrentLocalTime:string;     
    UserPicture:string;
  }