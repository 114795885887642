import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { appInitializerFactory } from '@angular/platform-browser/src/browser/server-transition';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {   
        if (this.authenticationService.isLogin()) {            
            request = request.clone({
                setHeaders: {
                    'X-AccessToken': localStorage.getItem('X-AccessToken'),
                    'X-ClientUID':  localStorage.getItem('GUID')
                }
            });
        }
        return next.handle(request);
    }
}