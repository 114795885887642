import { Component, OnInit, ViewChild, ElementRef, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { API, MessageType } from "../../services/api-global";

const api = new API();

import { AuthenticationService } from "../../services/authentication/authentication.service";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { from } from "rxjs";
import { FacecapturedComponent } from "../customs/facecaptured/facecaptured.component";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
  providers: [AuthenticationService],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  // @ViewChild("facecaptured") facecaptured: FacecapturedComponent;

  constructor(
    private router: Router,
    private autheticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private titleService: Title
  ) {
    this.titleService.setTitle(api.getTitle());
  }

  get clientURL() {
    return api.getConfig()["CLIENT_URL"];
  }
  get serverURL() {
    return api.getConfig()["SERVER_URL"];
  }
  get logoBase64() {
    return api.getConfig()["LOGO"];
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      company: ["", Validators.required],
      code: ["", Validators.required],
    });
    if (this.autheticationService.isLogin()) {
      api.openFullscreen();
      this.router.navigate(["/home"]);
    }
  }

  guid() {}
  async Login(Company: string, Code: string) {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    let uid: string = await api.generateUID();
    console.log(uid);
    this.autheticationService.Authenticade(Company, Code);
    return false;
  }

  get f() {
    return this.loginForm.controls;
  }

  /*PrepareCamera(){
    this.facecaptured.PrepareCamera();
  }*/
}
