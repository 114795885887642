import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../home/home.component';
import { TemplateComponent } from './template.component';
import { UserClockComponent } from '../user-clock/user-clock.component';


const routes: Routes = [
  {
    path: '',
    component: TemplateComponent,
    children: [
        {
          path: 'home',
          component: HomeComponent
        },
        {
          path: 'user',
          component: UserClockComponent
        }
        
    ]
}  
    
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TemplateRoutingModule { }