import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { CustomFieldService } from 'src/app/services/customcompanyfields/custom-field.service';
import { CustomField } from 'src/app/services/customcompanyfields/CustomField';
import { API, MessageType } from 'src/app/services/api-global';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { strictEqual } from 'assert';
import { Title } from '@angular/platform-browser';
import { KiosksettingsService } from 'src/app/services/KioskSettings/kiosksettings.service';
import { LoginResult } from 'src/app/services/authentication/LoginResult';
import { KioskSettings } from 'src/app/services/KioskSettings/KioskSettings';
import { HttpErrorResponse } from '@angular/common/http';
import { error } from 'protractor';



const api = new API();

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css']
})
export class TemplateComponent implements OnInit {

  user_login:string;
  themeMenu:string = "pcoded-navbar menu-light brand-lightblue icon-colored";
  themeHeader:string = "navbar pcoded-header navbar-expand-lg navbar-light header-lightblue headerpos-fixed";
  changeThemeForm:FormGroup;
  theme:string = "light";

  intervalId = setInterval(() => this.authService.login(), this.authService.interval);

  
  

  constructor(private authService: AuthenticationService,private kioskService:KiosksettingsService,private loader: LoaderService,private themeService:CustomFieldService,private formBuilder: FormBuilder,private titleService:Title) 
  {
    this.titleService.setTitle(api.getTitle());
    if(authService.verifyAccess())
       this.user_login = localStorage.getItem("UserLogin"); 
       
    
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  async ngOnInit() {
    
    this.changeThemeForm = this.formBuilder.group(
      {
        themeSelected:['']
      });  
      await api.generateUID();    
  }

  

  

  
  

  setUserLogin(user)
  {
    this.user_login = user;
  }

  LogOut()
  {
    this.authService.logOut();
  }

  InPath(path:string)
  {    
    return window.location.href.includes(path);
  }

  loadTheme()
  {    
    this.loader.show();
    switch(this.theme)
    {
      case "light":
        this.themeService.saveTheme(new CustomField({name:"theme",value:"light"})).subscribe((data)=>
        {   
          this.themeMenu = "pcoded-navbar menu-light brand-lightblue icon-colored";
          this.themeHeader = "navbar pcoded-header navbar-expand-lg navbar-light header-lightblue headerpos-fixed";
          this.loader.hidde();
        },(error)=>
        {
          api.Message(MessageType.Error,"Error Changing Theme","There is a Error changing theme");
          this.loader.hidde();
        })
      break;
      case "forest":
          this.themeService.saveTheme(new CustomField({name:"theme",value:"forest"})).subscribe((data)=>
          { 
              this.themeMenu = "pcoded-navbar";
              this.themeHeader = "navbar pcoded-header navbar-expand-lg navbar-light";
              this.loader.hidde();
          },(error)=>
          {
            api.Message(MessageType.Error,"Error Changing Theme","There is a Error changing theme");
            this.loader.hidde();
          })
      break;      
    }
  }

  changeTheme(val:string)
  {    
    this.theme = val;
    this.loadTheme();
  }

  PosibleErrorsKioskSettings(error:HttpErrorResponse)
  {
    this.loader.hidde();
    switch(error.status)
       {
        case 0:
          api.Message(MessageType.Error, "Connexion Error", "Please check your Internet.");
          break;         
         case 401:
         // this.authService.logOut();  
          api.Message(MessageType.Error,"Session Time Out","Please login again.");              
         break;
         case 403:
         api.Message(MessageType.Error,"Api Unaccesible","Not Access to Api");         
         break;          
         default:
         api.Message(MessageType.Error,"Unknow Error",error.message);        
         break; 
       }
  }

}
