import { Injectable } from '@angular/core';
import { API } from '../api-global';
import { HttpClient } from '@angular/common/http';
import { UserKiosk } from './UserKiosk';

const api = new API();

@Injectable({
  providedIn: 'root'
})
export class KioskUsersReadService {

  constructor(private http: HttpClient) { }

  getUsers(page:number,maxPerPage:number){
    var url = api.EnPoints.KioskUsers+"?filter.page="+page+"&filter.maxPerPage="+maxPerPage;   
    return this.http.get<UserKiosk[]>(url,{observe:'response'});
  }

  getUser(id:string){
    var url = api.EnPoints.KioskUsers+"/"+id;   
    return this.http.get<UserKiosk>(url,{observe:'response'});
  }
}
