import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { KioskSettings } from './KioskSettings';
import { API } from '../api-global';
import { CompanySettings } from '../settings/CompanySettings';
import { KioskCloking } from './KioskCloking';
import { formatDate } from '@angular/common';

const api = new API();

@Injectable({
  providedIn: 'root'
})
export class KiosksettingsService {

  constructor(private http: HttpClient) { }

  getSettings(){
    return this.http.get<KioskSettings>(api.EnPoints.KioskSettings,{observe:'response'});
  }

  getKioskSettings(id:string){    
    return this.http.get<KioskSettings>(api.EnPoints.KioskSettingsRead+"/"+id,{observe:'response'});
  }

  getCompanySettings(){
    return this.http.get<CompanySettings>(api.EnPoints.KioskCompanySettings,{observe:'response'});
  }

  getGlobalCompanyKiosSettings(){    
    return this.http.get<KioskSettings>(api.EnPoints.GlobalKioskSettingsRead,{observe:'response'});
  }

  sendKioskCloking(cloking:KioskCloking){
    return this.http.post(api.EnPoints.KioskCloking,cloking);
  }

  getKioskTimeFormatted(){
    return formatDate(new Date(),"yyyy-MM-ddThh:mm:ss","en-US");
  }
}
