import { Geolocation } from '../geolocation/Geolocation';
export class KioskSettings
{
    public constructor(init?:Partial<KioskSettings>) {
        Object.assign(this, init);
    
    }
    id: number;
    name: string;
    deviceUID: string;
    registrationStatus: number;
    clientDeviceType: number;
    clientIpAddress: string;
    clientUID: string;
    displayTimeFormat: string;
    overrideCompanySettings: boolean;
    registrationKey: string;
    requireDeviceLocation: boolean;
    requireUserPicture: boolean;
    showUsersAvatar: boolean;
    showUsersList: boolean;
    useBarCodeVerification: boolean;
    useCardVerification: boolean;
    useFaceVerification: boolean;
    useFingerPrintVerification: boolean;
    usePinVerification: boolean;
    useQRCodeVerification: boolean;
    requireDeviceGeoLocation: boolean;
    enforceDeviceGeoFence: boolean;
    cameraMode:number;
    onlineKioskGeoLocationsVM: Geolocation [];   
  }