import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomField } from './CustomField';
import { API } from '../api-global';

const api = new API();

@Injectable({
  providedIn: 'root'
})
export class CustomFieldService {

  constructor(private http: HttpClient) { }  

  getTheme()
  {
    return this.http.get<CustomField>(api.EnPoints.CompanyCustomField+"?name=theme");
  }  

  createThemeIfNotExist()
  {
    let theme = {
      name: "theme",
      value:"light"     
    }     
    return this.http.post(api.EnPoints.CompanyCustomField,theme);   
  }

  saveTheme(val:CustomField)
  {    
    return this.http.put(api.EnPoints.CompanyCustomField,val);
  }

 

  
}
