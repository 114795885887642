export class CompanySettings
{
    public constructor(init?:Partial<CompanySettings>) {
        Object.assign(this, init);
    }   
  payPeriodFrequency: number;
  payPeriodStartDate: string;
  companyAlias: string;
  companyName: string;
  companyCode: string;
  companyLogo: string;
  countryID: number;
  telephoneFormat: string;
  showTime: boolean;
  showIconsReports: boolean;
  showIconsEmployeeTimecard: boolean;
  showTimeCardStatus: number;
  displayNameFormat: number;
  useSchedule: boolean;
  showLocationOnSchedule: boolean;
  roundingTypeID: number;
  roundingTypeName: string;
  roundingFirtLastOnly: boolean;
  automaticRounding: boolean;
  useCompTime: boolean;
  useCompTimeHours: number;
  outOfShiftAsRegular: boolean;
  semiMonthlyStartDay: number;
  semiMonthlyEndDay: number;
  showOriginalSchedule: boolean;
  firstDayOfWeek: number;
  firstDayOfWeekNumber: number;
  requiredNotesOnTimecards: boolean;
  useSpecialJobs: boolean;
  enableAbsencesTowardOT: boolean;
  useProjJobBulkAssignment: boolean;
  enableUnions: boolean;
  unionsOverlapHours: boolean;
  useACA: boolean;
  absencesTowardsOTOnExportType: number;
  payRegularWithJobRate: boolean;
  payOTWithJob: boolean;
  approachingTimeAlertNotification: boolean;
  arrivalDepartureAlertNotification: boolean;
  excludedEmployeeTypes: string;
  zipCodeMask: string;
  allowDuplicateBiometrics: boolean;
  exportOTForSalariedEmployees: boolean;
  exportOT2ForSalariedEmployees: boolean;
  accessAPI: boolean;
  behaviorGroupingStrategy: boolean;
  userHourlyRateDecimalPlaces: number;
  showBonusHoursTab: boolean;
  enablePeriodStartTime: boolean;
  useTips: boolean;
  showPunchIndicatorTimecard: boolean;
  getAutomaticHoursIfClockIN: boolean;
  prorateAccruedHours: boolean;
  timecardShowSummaryReport: boolean;
  showFullPrecisionHintTimecard: boolean;
  bulkTimecardShowEditNote: boolean;
  bulkTimecardShowEditLBT: boolean;
  useCustomRoundingExcDefRule: boolean;
  showConstructionTimeCardTab: boolean;
  useApprovalProcessForSalaryEmployees: boolean;
  requirePictureMobileClock: boolean;
  useMobileTimeToPunch: boolean;
}  
