import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Settings } from 'src/app/services/settings/Settings';
import { Config } from 'src/app/services/authentication/Config';
import { Router } from '@angular/router';
import {WebcamImage} from 'ngx-webcam';




@Component({
  selector: 'app-user-clock',
  templateUrl: './user-clock.component.html',
  styleUrls: ['./user-clock.component.css']
})
export class UserClockComponent implements OnInit {

  pingMode: boolean = false;
  cardMode: boolean = false;
  fingerprintMode: boolean = false;
  faceMode: boolean = false;
  settings: Config = null;

  //Camera
  // latest snapshot
  public webcamImage: WebcamImage = null;
  handleImage(webcamImage: WebcamImage) {
    this.webcamImage = webcamImage;
  }
  //End Camera




  constructor(private authentication: AuthenticationService, private router: Router) { }

  ngOnInit() {
    this.settings = this.authentication.loadconfig();
    if (this.settings == undefined || this.settings == null) {
      localStorage.clear();
      this.router.navigate(['/login']);
      return;
    }
    switch (this.settings.authenticationMode) {
      case "1":
        this.pingMode = true;
        break
      case "2":
        this.cardMode = true;
        break;
      case "3":
        this.fingerprintMode = true;
        break;
      case "4":       
        this.faceMode = true;
        break;
      default:
        localStorage.clear();
        this.router.navigate(['/login']);
        break;
    }
  }

 



}
