import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { API, MessageType } from 'src/app/services/api-global';

declare var faceapi: any;
const api = new API();

@Component({
  selector: 'app-facecaptured',
  templateUrl: './facecaptured.component.html',
  styleUrls: ['./facecaptured.component.css']
})
export class FacecapturedComponent implements OnInit {

  @ViewChild("video") video: ElementRef; //canvas
  @ViewChild("canvas") canvas: ElementRef;

  model: any;
  isDataLoading = true;

  visibleUIPercent:boolean = false;
  facePercent:number = 0;
  faceCount:number = 0;
  faceCountTotal:number = 100;
  intervalFaceRecognition;
  imageCaptured:string = "";
  finished:boolean = false;
  info:string = "Loading please wait...";
  changeThreshold:number=50;
  localSteam:MediaStream;
  timer:number = 800;

  @Output() public pictureTaken = new EventEmitter<string>();

  public async PrepareCamera(quality:number) {   
    switch(quality){
      case 3://low
        this.changeThreshold=50;
        this.timer = 800;
        break;
      case 4://medium
        this.changeThreshold=25;
        this.timer = 400;
        break;
      case 5://hight
        this.changeThreshold=20;
        this.timer = 200;
        break;
    } 
    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri("assets/models")
    ]).then(async data => {
      this.isDataLoading = false;           
      await this.startVideo();
    });
  }

  async startVideo() {
    const vid = this.video.nativeElement;
    if (navigator.mediaDevices.getUserMedia) {      
      await  navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(stream => {
          vid.srcObject = stream; 
          this.localSteam = stream;               
          setTimeout(()=>{this.onPlay()},500); 
        })
        .catch(error => {
          api.Message(MessageType.Error,"Error on Camera","Description:"+error);
          this.info = "Camera Error, please allow camera.";
        });       
    } else {
      api.Message(MessageType.Error,"No Camera","No media device Found, please connect a camera.");
      this.info = "No Camera Found, please check your camera.";
    }
  }

  onPlay() {        
    const video = this.video.nativeElement;
    const can = this.canvas.nativeElement;
    const canvas = faceapi.createCanvasFromMedia(video);
    can.append(canvas);
    const displaySize = { width: video.width, height: video.height };
    faceapi.matchDimensions(canvas, displaySize);
    this.visibleUIPercent = true;
    this.intervalFaceRecognition = setInterval(async () => {
      const detections = await faceapi
        .detectAllFaces(video, new faceapi.TinyFaceDetectorOptions()); 
      const resizedDetections = faceapi.resizeResults(detections, displaySize);
      canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
      faceapi.draw.drawDetections(canvas, resizedDetections);

      switch(resizedDetections.length){
        case 0:
          this.info = "Look to camera please.";
          if(this.faceCount>=this.changeThreshold)
             this.faceCount-=this.changeThreshold;
          else
             this.faceCount=0;
          break;
        case 1:
          this.info = "Taking Photo, don't move.";
          if(this.faceCount<this.faceCountTotal)
             this.faceCount+=this.changeThreshold;
          else
             this.faceCount=this.faceCountTotal;
          break;
        default:
          this.info = "Too many People in front of Camera.";
          this.faceCount=-100;
          break;
      }
      this.facePercent = this.faceCount>0 ? (this.faceCount*100/this.faceCountTotal):0;   
      this.facePercent = this.facePercent>100 ? 100:this.facePercent; 
      if(this.facePercent==100){
        const capt = faceapi.createCanvasFromMedia(video); 
        this.imageCaptured = capt.toDataURL();
        this.finished = true;
        this.info = "Face Captured.";
        this.stopVideo();
        this.pictureTaken.emit(this.imageCaptured);
      }      
    }, this.timer);
  }

  stopVideo(){
    const video = this.video.nativeElement;
    clearInterval(this.intervalFaceRecognition);
        video.pause();
        this.localSteam.getTracks().forEach(element => {
          element.stop();
        }); 
  }

  constructor() { }

  ngOnInit() {
  }

}
