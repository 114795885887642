import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http'
import { tap } from 'rxjs/operators';
import {API, MessageType} from '../api-global'
import { Router } from '@angular/router';
import { LoaderService } from '../loader/loader.service';
import * as CryptoJS from 'crypto-js';  
import { Config } from './Config';
import { LoginResult } from './LoginResult';
import { error } from 'protractor';
import { KioskSettings } from '../KioskSettings/KioskSettings';

const api = new API();

const pass = "58dfc-4534f";
const settingStorage = "settingsStorage";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  interval:number = 60000*5;
  
  constructor(private router: Router,private http: HttpClient,private progress: LoaderService) {
    api.generateUID();
  }  

  logOut()
  {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  getInterval(){
    return this.interval;
  }

  isLogin()
  {
    return (localStorage.getItem("Company")!=undefined);
  }

  verifyAccess()
  {
    if(!this.isLogin())
    {
      this.logOut();
      return false;
    }
       return true;
  }

  Authenticade(company: string, code:string)
  {       
    this.progress.show();     
  //  console.log(localStorage.getItem('GUID')); 
    const httpOptions = {
     headers:   {
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + btoa(company+':'+code+':'+localStorage.getItem('GUID'))      
     },
     observe: 'response' as 'body'     
    };
    const data = JSON.stringify("");    
    
    this.http.post(api.EnPoints.Login,data,httpOptions)
    .pipe(tap(
      (res:HttpResponse<any>)=>
      {         
        api.openFullscreen();  
        let result:KioskSettings = res.body;        
        localStorage.setItem("Company",company); 
        this.saveKioskSettings(result); 
        localStorage.setItem("X-AccessToken",res.headers.get("X-AccessToken"));          
        api.Message(MessageType.Success,"Welcome "+code,"Login successful");              
        this.progress.hidde();
        this.router.navigate(["/home"]);
        //window.location.href='/home';             
      }
     ,(error:HttpErrorResponse)=> {this.PosibleErrors(error);this.progress.hidde();}
     )).subscribe();   
     
  }

  async loginEnpoint(data:KioskSettings):Promise<HttpResponse<any>>
  {       
    let guid:string = await api.generateUID();
    const httpOptions = {
      headers:   {
       'Content-Type': 'application/json',
       'Authorization': 'Basic ' + btoa(localStorage.getItem("Company")+':'+data.id+':'+data.registrationKey+':'+data.deviceUID+":"+guid)    
      },
      observe: 'response' as 'body'     
     };
     let response:HttpResponse<any> = <HttpResponse<any>>await this.http.post(api.EnPoints.LoginAutomatic,data,httpOptions).toPromise().catch(error=>{this.PosibleErrors(error)});
     localStorage.setItem("X-AccessToken",response.headers.get("X-AccessToken"));           
    return response;
  }

  async login(){
    let data:KioskSettings = this.loadKioskResult();       
    await this.loginEnpoint(data); 
  }

  saveKioskSettings(json:KioskSettings){
    let js = JSON.stringify(json);  
    localStorage.setItem("Kiosk",CryptoJS.AES.encrypt(js,pass).toString());
  }

  loadKioskResult(){    
    try{
      let encrip = localStorage.getItem("Kiosk");   
      let descript = CryptoJS.AES.decrypt(encrip,pass).toString(CryptoJS.enc.Utf8);       
      let config:KioskSettings = JSON.parse(descript);   
      return config;
    }
    catch(e){
      console.log(e);
      return null;
    }    
  }

  saveConfig(json:Config){   
    let js = JSON.stringify(json);  
    localStorage.setItem(settingStorage,CryptoJS.AES.encrypt(js,pass).toString());    
  }

  loadconfig(){
    try{
      let encrip = localStorage.getItem(settingStorage);   
      let descript = CryptoJS.AES.decrypt(encrip,pass).toString(CryptoJS.enc.Utf8);       
      let config:Config = JSON.parse(descript);   
      return config;
    }
    catch(e){
      return null;
    }    
  }

  PosibleErrors(error:HttpErrorResponse)
  {
    switch(error.status)
       {
        case 0:
          api.Message(MessageType.Error, "Connexion Error", "Please check your Internet.");
          break;
        case 400:
         api.Message(MessageType.Error,"Kiosk already Initialized","Please if you want to relogin this Kiosk, reset it in admin web");
         break; 
         case 401:
         api.Message(MessageType.Error,"Wrong Credentials","Company or Code Incorrect");
         this.logOut();       
         break;
         case 403:
         api.Message(MessageType.Error,"Api Unaccesible","Not Access to Api");         
         break; 
         case 404:
         api.Message(MessageType.Error,"Api Unaccesible","Not Access to Api");
         break; 
         default:
         api.Message(MessageType.Error,"Unknow Error",error.message);        
         break; 
       }
  }
}
